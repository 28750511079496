import { Category } from '@prisma/client';
import { ResponseData, ResponseList } from '../server/general';
import { CategoryWithCount } from '../types/category';
import { customFetch } from '../utils';

export const fetchCategories = async (filter: string, showAll: boolean) => {
  const responseData = await customFetch<ResponseList<CategoryWithCount>>(
    `/api/category?filter=${filter}&showAll=${showAll}`,
    {
      method: 'GET',
    }
  );
  return responseData.items;
};

export const fetchCategory = async (id: number) => {
  const responseData = await customFetch<ResponseData<CategoryWithCount>>(
    `/api/category/${id}`,
    {
      method: 'GET',
    }
  );
  return responseData.data;
};

export const deleteCategory = async (id: number) => {
  await customFetch<void>(`/api/category/${id}`, {
    method: 'DELETE',
  });
};

export const createCategory = async (
  name: string,
  description: string
): Promise<Category | undefined> => {
  const responseData = await customFetch<ResponseData<Category>>(
    '/api/category',
    {
      method: 'POST',
      body: JSON.stringify({
        name: name,
        description: description,
      }),
    }
  );

  return responseData.data;
};

export const updateCategory = async (
  id: number,
  name: string,
  description: string
): Promise<Category | undefined> => {
  const responseData = await customFetch<ResponseData<Category>>(
    `/api/category/${id}`,
    {
      method: 'PATCH',
      body: JSON.stringify({
        name: name,
        description: description,
      }),
    }
  );

  return responseData.data;
};
