import { Pattern, PatternReferenceImage } from '@prisma/client';
import { ResponseData, ResponseList } from '../server/general';
import {
  PatternAndReferenceImage,
  PatternData,
  PatternWithColorsCount,
} from '../types/pattern';
import {
  ImageTransferDitherEnum,
  ImageTransferMethodEnum,
  PatternSize,
  Point,
  Size,
  StitchTypeEnum,
} from '../types/general';
import { BeadInfo, GridData } from '../types/palette';
import { customFetch } from '../utils';

export const fetchPatterns = async (
  filter: string,
  showAll: boolean,
  limit: number,
  categoryId?: number
) => {
  const responseData = await customFetch<ResponseList<PatternWithColorsCount>>(
    `/api/pattern?filter=${filter}&limit=${limit}&showAll=${showAll}${
      categoryId ? `&categoryId=${categoryId}` : ''
    }`
  );
  return responseData.items;
};

export const fetchPattern = async (id: number) => {
  const responseData = await customFetch<
    ResponseData<PatternAndReferenceImage>
  >(`/api/pattern/${id}`, {
    method: 'GET',
  });
  return responseData.data;
};

export const savePattern = async (pattern: PatternData) => {
  const responseData = await customFetch<ResponseData<Pattern>>(
    `/api/pattern`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(pattern),
    }
  );
  return responseData.data;
};

export const deletePattern = async (id: number) => {
  await customFetch(`/api/pattern/${id}`, {
    method: 'DELETE',
  });
};

export const transferImage = async (
  grid: GridData,
  patternPos: Point,
  patternSizeInPx: Size,
  patternSize: PatternSize,
  referenceImagePos: Point,
  referenceImage: Partial<PatternReferenceImage>,
  stitchType: StitchTypeEnum,
  imageTransferMethod: ImageTransferMethodEnum,
  imageTransferDither: ImageTransferDitherEnum,
  maxColors: number,
  patternZoom: number,
  customPaletteId?: number,
  paletteId?: number
): Promise<BeadInfo[][] | undefined> => {
  const responseData = await customFetch<
    ResponseData<BeadInfo[][] | undefined>
  >(`/api/pattern/transfer-image`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      grid,
      patternPos,
      patternSizeInPx,
      patternSize,
      referenceImagePos,
      referenceImage,
      stitchType,
      imageTransferMethod,
      imageTransferDither,
      maxColors,
      patternZoom,
      customPaletteId,
      paletteId,
    }),
  });
  return responseData.data;
};
