import {
  Category,
  PaletteDetail,
  Pattern,
  PatternReferenceImage,
  Prisma,
} from '@prisma/client';
import toast from 'react-hot-toast';
import { deleteCategory, fetchCategory } from './api/category';
import { deletePattern, savePattern } from './api/pattern';
import { PatternPainter } from './canvas/pattern-painter';
import {
  CUSTOM_COLORS_PALETTE_ID,
  EMPTY_CATEGORY,
  INITIAL_BEAD_SIZE,
  PATTERN_TOP_PANEL_HEIGHT,
  PREVIEW_CANVAS_SIZE,
} from './constants';
import {
  AlertModalOptions,
  AnalyticEventNameEnum,
  ConfirmModalOptions,
  HistoryData,
  HistoryDataItem,
  PatternSize,
  Point,
  Rect,
  Size,
  StitchTypeEnum,
  UnitOfMeasureEnum,
} from './types/general';
import {
  BeadInfo,
  GridData,
  GridRow,
  PaletteBeadColor,
  PaletteEx,
  PaletteWithDetails,
} from './types/palette';
import { PatternData, PatternWithColorsCount } from './types/pattern';
import clsx, { ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function isNumber(str: string) {
  return /^\d+$/.test(str);
}

function getStitchBeadSize(stitchType: StitchTypeEnum) {
  let size: Size = { width: 0, height: 0 };

  switch (stitchType) {
    case StitchTypeEnum.Brick:
      size = { width: 0.17587, height: 0.135 };
      break;

    case StitchTypeEnum.Peyote:
      size = { width: 0.135, height: 0.17587 };
      break;

    case StitchTypeEnum.Loom:
      size = { width: 0.1356, height: 0.175 };
      break;
  }

  return size;
}

const cmToInchRatio = 0.3937007874;

export function convertPatternSizeToPatternPhysicalSize(
  patternSize: PatternSize,
  stitchType: StitchTypeEnum,
  uom: UnitOfMeasureEnum
): Size {
  const size = getStitchBeadSize(stitchType);

  if (uom === UnitOfMeasureEnum.inch) {
    size.width *= cmToInchRatio * patternSize.cols;
    size.height *= cmToInchRatio * patternSize.rows;
  } else {
    size.width *= patternSize.cols * (uom === UnitOfMeasureEnum.mm ? 10 : 1);
    size.height *= patternSize.rows * (uom === UnitOfMeasureEnum.mm ? 10 : 1);
  }

  let roundFactor = 100;

  if (uom === UnitOfMeasureEnum.inch) {
    roundFactor = 1000;
  }

  // Round size to 2 decimal digits
  size.width = Math.round(size.width * roundFactor) / roundFactor;
  size.height = Math.round(size.height * roundFactor) / roundFactor;

  return size;
}

export function convertPatternPhysicalSizeToPatternSize(
  patternPhysicalSize: Size,
  stitchType: StitchTypeEnum,
  uom: UnitOfMeasureEnum
): PatternSize {
  const size = getStitchBeadSize(stitchType);

  const patternSize: PatternSize = { rows: size.height, cols: size.width };

  if (uom === UnitOfMeasureEnum.inch) {
    patternSize.cols = patternPhysicalSize.width / (cmToInchRatio * size.width);
    patternSize.rows =
      patternPhysicalSize.height / (cmToInchRatio * size.height);
  } else {
    patternSize.cols = patternPhysicalSize.width / size.width;
    patternSize.rows = patternPhysicalSize.height / size.height;
  }

  // Round
  patternSize.cols = Math.round(patternSize.cols);
  patternSize.rows = Math.round(patternSize.rows);

  return patternSize;
}

export function getBeadSize(stitchType: StitchTypeEnum, zoom: number): Size {
  const initialBeadSize = deepClone(INITIAL_BEAD_SIZE);
  switch (stitchType) {
    case StitchTypeEnum.Peyote:
      return {
        width: initialBeadSize.height * zoom,
        height: initialBeadSize.width * zoom,
      };
    case StitchTypeEnum.Brick:
      return {
        width: initialBeadSize.width * zoom,
        height: initialBeadSize.height * zoom,
      };
    case StitchTypeEnum.Loom:
      return {
        width: initialBeadSize.height * zoom,
        height: initialBeadSize.width * zoom,
      };
  }
}

export function getGridFromPattern(
  pattern: Pattern,
  allPaletteDetails: PaletteDetail[]
): GridData {
  const gridRawData = pattern.beadsData as Prisma.JsonArray[];
  const grid: GridData = [];

  for (let row = 0; row < pattern.rows; row++) {
    const gridRow: GridRow = [];

    for (let col = 0; col < pattern.cols; col++) {
      const pd = allPaletteDetails.find(
        (pd) => pd.id === gridRawData[row]?.[col]
      );
      if (pd) {
        gridRow.push({
          id: pd.id,
          name: pd.name,
          color: pd.color,
        });
      } else {
        gridRow.push(null);
      }
    }

    grid.push(gridRow);
  }

  return grid;
}

export function getBeadsDataFromGrid(
  grid: GridData,
  rows: number,
  cols: number
): number[][] {
  const beadsData: number[][] = [];

  for (let row = 0; row < rows; row++) {
    const beadsDataRow: number[] = [];

    for (let col = 0; col < cols; col++) {
      const id = grid[row]?.[col]?.id;
      if (id) {
        beadsDataRow.push(id);
      } else {
        beadsDataRow.push(-1);
      }
    }

    beadsData.push(beadsDataRow);
  }

  return beadsData;
}

export function getZoomFitFromPattern(pattern: Pattern, maxSize: Size): number {
  const beadSize = getBeadSize(pattern.stitchTypeId as StitchTypeEnum, 1.0);
  const patternSize = {
    width: (pattern.cols + 1) * beadSize.width,
    height: (pattern.rows + 1) * beadSize.height,
  };

  const zoomWidth = maxSize.width / patternSize.width;
  const zoomHeight = maxSize.height / patternSize.height;

  return Math.min(zoomWidth, zoomHeight);
}

export function getCanvasSize(
  patternSize: PatternSize,
  stitchType: StitchTypeEnum,
  zoom: number
) {
  const beadSize = getBeadSize(stitchType, zoom);
  return {
    width:
      (patternSize.cols + (stitchType === StitchTypeEnum.Brick ? 0.5 : 0)) *
        beadSize.width +
      1,
    height:
      (patternSize.rows + (stitchType === StitchTypeEnum.Peyote ? 0.5 : 0)) *
        beadSize.height +
      1,
  };
}

export function generatePatternPreviews(
  patterns: Pattern[],
  allPaletteDetails: PaletteDetail[],
  maxSize: Size
): string[] {
  const canvas = document.createElement('canvas');
  canvas.setAttribute('width', maxSize.width.toString());
  canvas.setAttribute('height', maxSize.height.toString());
  canvas.setAttribute('id', 'dynamicCanvas');

  const body = document.querySelector('body');

  body?.appendChild(canvas);
  const ctx = canvas.getContext('2d');

  let previews: string[] = [];

  if (ctx) {
    previews = patterns.map((pattern) => {
      const zoom = getZoomFitFromPattern(pattern, maxSize);
      const canvasSize = getCanvasSize(
        { cols: pattern.cols, rows: pattern.rows },
        pattern.stitchTypeId as StitchTypeEnum,
        zoom
      );
      canvas.setAttribute('width', canvasSize.width.toString());
      canvas.setAttribute('height', canvasSize.height.toString());

      new PatternPainter(
        canvas,
        ctx,
        pattern.stitchTypeId as StitchTypeEnum,
        pattern.cols,
        pattern.rows,
        getGridFromPattern(pattern, allPaletteDetails),
        zoom,
        false
      );

      return canvas.toDataURL();
    });
  } else {
    throw Error('No context for canvas');
  }

  body?.removeChild(canvas);

  return previews;
}

export const patternsToPatternPreviews = (
  patterns: PatternWithColorsCount[],
  allPaletteDetails: PaletteDetail[]
) => {
  const patternPreviews = generatePatternPreviews(
    patterns,
    allPaletteDetails,
    PREVIEW_CANVAS_SIZE
  );

  const newPatternPreviews: Record<number, string> = {};

  patternPreviews.forEach((preview, index) => {
    newPatternPreviews[patterns[index].id] = preview;
  });

  return newPatternPreviews;
};

export const getIconFillColor = (selected?: boolean) => {
  return selected ? '#F7F7F7' : 'white';
};

export const getIconStrokeColor = (disabled?: boolean) => {
  return disabled ? '#A5A5A5' : '#1A1919';
};

export const saveHistory = (
  history: HistoryData,
  setHistory: (newHistory: HistoryData) => void,
  updated: Partial<HistoryDataItem>,
  replace: boolean = false
) => {
  let newHistory = deepClone(history);

  if (replace) {
    newHistory = {
      ...newHistory,
      present: { ...newHistory.present, ...updated },
    };
  } else {
    newHistory = {
      ...newHistory,
      past: [...newHistory.past, newHistory.present],
      present: { ...newHistory.present, ...updated },
    };
  }
  if (JSON.stringify(newHistory.present) !== JSON.stringify(history.present)) {
    setHistory(deepClone(newHistory));
  }
};

export const resetHistory = (
  setHistory: (newHistory: HistoryData) => void,
  present: HistoryDataItem
) => {
  setHistory({
    past: [],
    present: deepClone(present),
    future: [],
  });
};

export const isMac = () => {
  return navigator.userAgent.indexOf('Mac OS X') !== -1;
};

export const getDistinctBeadInfoNames = (grid: BeadInfo[][]) => {
  const flatArray: BeadInfo[] = grid
    .reduce((acc: BeadInfo[], val) => acc.concat(val), [])
    .filter((item) => item);

  const distinctNamesWithCount: Record<string, number> = {};

  flatArray.forEach((item) => {
    distinctNamesWithCount[item.name] =
      (distinctNamesWithCount[item.name] || 0) + 1;
  });

  return distinctNamesWithCount;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepClone = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
};

export interface BeadChartCell {
  letter: string;
  count: number;
}

export interface BeadChartRow {
  rowName: string;
  cells: BeadChartCell[];
}

export const generateBeadChart = (
  pattern: Pattern,
  grid: GridData
): BeadChartRow[] => {
  const beadChartRows: BeadChartRow[] = [];

  let startFrom = 'L';

  if (pattern.stitchTypeId === StitchTypeEnum.Brick) {
    startFrom = 'R';
  }

  for (let row = 0; row < pattern.rows; row++) {
    let rowName = `Row ${row + 1} (${startFrom})`;

    if (pattern.stitchTypeId === StitchTypeEnum.Peyote) {
      if (row === 0) {
        rowName = `Row 1&2 (${startFrom})`;
      } else {
        rowName = `Row ${row + 2} (${startFrom})`;
      }
    }

    const paletteBeadColors = getPaletteBeadColors(
      grid,
      pattern.cols,
      pattern.rows
    );

    const cells: BeadChartCell[] = [];

    let count = 0;
    let lastLetter = undefined;

    let i = startFrom === 'L' ? 0 : pattern.cols - 1;
    let exit = false;

    while (!exit) {
      if (!grid[row]) {
        debugger;
        console.warn(`Row ${row} is missing from the grid`);
        break;
      }

      const bi = grid[row][i];

      const label = bi?.name || '';
      const color = bi?.color || '';

      let currentLetter = '';

      if (label === '') {
        currentLetter = '∅';
      } else {
        currentLetter =
          paletteBeadColors.find((pbc) => pbc.color === color)?.label || '';
      }

      if (lastLetter !== currentLetter) {
        if (lastLetter) {
          cells.push({ letter: lastLetter, count: count });
        }
        count = 1;
        lastLetter = currentLetter;
      } else {
        count++;
      }

      if (startFrom === 'L') {
        i++;
        if (i === grid[row].length) {
          exit = true;
        }
      } else {
        i--;

        if (i === 0) {
          exit = true;
        }
      }
    }

    if (lastLetter && count > 0) {
      cells.push({ letter: lastLetter, count: count });
    }

    const beadChartRow: BeadChartRow = {
      rowName,
      cells,
    };

    beadChartRows.push(beadChartRow);
    startFrom = startFrom === 'L' ? 'R' : 'L';
  }

  return beadChartRows;
};

export const convertPixelsToMm = (pixels: number): number => {
  const dpi = 96;
  const inches = pixels / dpi;
  const mm = inches * 25.4;
  return mm;
};

const getNextChar = (c: string) => {
  if (c.length === 1) {
    if (c === 'Z') {
      return 'A2';
    } else {
      return String.fromCharCode(c.charCodeAt(0) + 1);
    }
  } else {
    const number = parseInt(c[1]);
    if (c[0] === 'Z') {
      return `A${number + 1}`;
    } else {
      return String.fromCharCode(c.charCodeAt(0) + 1) + number;
    }
  }
};

export const getPaletteBeadColors = (
  grid: GridData,
  cols: number,
  rows: number
): PaletteBeadColor[] => {
  const paletteBeadColors: PaletteBeadColor[] = [];
  let currentLetter = 'A';

  for (let row = 0; row < rows; row++) {
    for (let col = 0; col < cols; col++) {
      const bi = grid[row]?.[col];

      if (bi) {
        const bpc = paletteBeadColors.find((pbc) => pbc.color === bi.color);

        if (bi.color) {
          if (!bpc) {
            paletteBeadColors.push({
              id: bi.id,
              name: bi.name,
              color: bi.color,
              label: currentLetter,
              count: 1,
            });
            currentLetter = getNextChar(currentLetter);
          } else {
            bpc.count++;
          }
        }
      }
    }
  }

  return paletteBeadColors;
};

export const onDeleteCategory = async (
  category: Category,
  setConfirmModalOptions: (options: ConfirmModalOptions | undefined) => void,
  setAlertModalOptions: (options: AlertModalOptions | undefined) => void,
  refreshCategories: () => Promise<void>,
  setSelectedCategory: (category: Category) => void
) => {
  const existingCategory = await fetchCategory(category.id);

  if (existingCategory?.count === 0) {
    setConfirmModalOptions({
      title: 'Delete category',
      message: `Are you sure you want to delete the category "${category.name}"?`,
      confirmText: 'Delete',
      cancelText: 'Cancel',
      onConfirm: async () => {
        await deleteCategory(category.id);
        await refreshCategories();
        setSelectedCategory(EMPTY_CATEGORY);

        logAnalyticsEvent(AnalyticEventNameEnum.DeleteCategory, {
          name: category.name,
        });
      },
    });
  } else {
    setAlertModalOptions({
      title: 'Delete category',
      message: `The category "${category.name}" cannot be deleted because it is in use.`,
      confirmText: 'OK',
    });
  }
};

export const onDeletePattern = async (
  pattern: Pattern,
  setConfirmModalOptions: (options: ConfirmModalOptions | undefined) => void,
  refreshPatterns: () => Promise<void>,
  setSelectedPattern: (pattern: Pattern | undefined) => void
) => {
  setConfirmModalOptions({
    title: 'Delete category',
    message: `Are you sure you want to delete the pattern "${pattern.name}"?`,
    confirmText: 'Delete',
    cancelText: 'Cancel',
    onConfirm: async () => {
      await deletePattern(pattern.id);
      await refreshPatterns();
      setSelectedPattern(undefined);
    },
  });
};

export const notifyPatternSaved = () => toast.success('Pattern saved!');

export const logWithTrace = (message: string) => {
  const error = new Error();
  const stackTrace = error.stack || '';
  const calls = stackTrace.split('\n').slice(2);
  const tsCalls = calls.filter(
    (call) => call.includes('.ts:') || call.includes('.tsx:')
  );
  const lastThreeTsCalls = tsCalls.slice(-3);
  console.error(message + '\n' + lastThreeTsCalls.join('\n'));
};

export const getFilenameFromUrl = (url: string): string => {
  const imageUrlSplit = url.split('/');
  const filename = imageUrlSplit[imageUrlSplit.length - 1];
  return filename;
};

export const getPaletteKey = (palette: PaletteEx): string => {
  return palette.isCustomPalette
    ? `custom-${palette.id}`
    : `palette-${palette.id}`;
};

export const savePatternInternal = async (
  pattern: Pattern,
  selectedPalette: PaletteWithDetails | undefined,
  grid: GridData,
  patternSize: PatternSize,
  patternPosition: Point,
  patternZoom: number,
  selectedStitchType: StitchTypeEnum,
  referenceImage: PatternReferenceImage | null,
  isTemp: boolean
) => {
  const paletteId = selectedPalette?.palette.isCustomPalette
    ? null
    : selectedPalette?.palette.id || null;
  const customPaletteId = selectedPalette?.palette.isCustomPalette
    ? selectedPalette?.palette.id || null
    : null;

  const newPattern = {
    ...pattern,
    paletteId,
    customPaletteId,
    categoryId: pattern.categoryId || 0,
    stitchTypeId: selectedStitchType,
    rows: patternSize.rows,
    cols: patternSize.cols,
    posX: patternPosition.x,
    posY: patternPosition.y,
    zoom: patternZoom,
    rotation: 0,
    beadsData: getBeadsDataFromGrid(grid, patternSize.rows, patternSize.cols),
    isTemp,
    referenceImage: referenceImage,
  } as PatternData;

  return await savePattern(newPattern);
};

export const logAnalyticsEvent = (
  eventName: AnalyticEventNameEnum,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventArgs: any
) => {
  if (process.env.NEXT_PUBLIC_GA4_TRACKING_ID) {
    gtag('event', eventName, eventArgs);
  } else {
    console.log(`Analytics event: ${eventName}`, eventArgs);
  }
};

export const sendErrorReport = (errorMessage: string) => {
  fetch('/api/general/error', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      error: errorMessage,
    }),
  });
  logAnalyticsEvent(AnalyticEventNameEnum.Error, {
    error: errorMessage,
  });
};

export const areRectsIntersecting = (r1: Rect, r2: Rect): boolean => {
  return (
    r1.x < r2.x + r2.width &&
    r1.x + r1.width > r2.x &&
    r1.y < r2.y + r2.height &&
    r1.y + r1.height > r2.y
  );
};

export const getSelectableIconClasses = (isSelected: boolean): string => {
  return `cursor-pointer rounded ${
    isSelected ? 'text-primary bg-background-dark2' : 'text-secondary2 bg-white'
  }`;
};

export const getCenteredImageBounds = (
  patternPosition: Point,
  patternSize: PatternSize,
  stitchType: StitchTypeEnum,
  imageSize: Size
): Rect => {
  const patternSizeInPx = getCanvasSize(patternSize, stitchType, 1.0);

  let resizeFactor = patternSizeInPx.width / imageSize.width;

  if (imageSize.height * resizeFactor > patternSizeInPx.height) {
    resizeFactor = patternSizeInPx.height / imageSize.height;
  }

  const width = imageSize.width * resizeFactor;
  const height = imageSize.height * resizeFactor;
  const x = patternPosition.x + patternSizeInPx.width / 2 - width / 2;
  const y =
    patternPosition.y +
    patternSizeInPx.height / 2 -
    height / 2 +
    PATTERN_TOP_PANEL_HEIGHT;

  return {
    x,
    y,
    width,
    height,
  };
};

export const getCanvasBounds = (): Rect => {
  const bounds = document.querySelector('#canvas')?.getBoundingClientRect();
  return {
    x: bounds?.left || 0,
    y: bounds?.top || 0,
    width: bounds?.width || 0,
    height: bounds?.height || 0,
  };
};

export const getReferenceImageBounds = (): Rect => {
  const bounds = document
    .querySelector('#referenceImage')
    ?.getBoundingClientRect();

  return {
    x: bounds?.left || 0,
    y: bounds?.top || 0,
    width: bounds?.width || 0,
    height: bounds?.height || 0,
  };
};

export const customFetch = async <T>(
  input: RequestInfo | URL,
  init?: RequestInit | undefined
) => {
  let response;
  try {
    response = await fetch(input, init);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return (await response.json()) as T;
  } catch (error) {
    console.error('Error fetching data: ', error);
    throw error;
  }
};

export const isCustomColor = (paletteDetail: PaletteDetail) => {
  return paletteDetail?.paletteId === CUSTOM_COLORS_PALETTE_ID;
};

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export const shouldRenderHeaderFooter = (pathname: string) =>
  pathname !== '/landing' &&
  pathname !== '/privacy-policy' &&
  pathname !== '/terms-of-use';
