import invert from 'invert-color';
import { BEAD_STROKE_COLOR, BEAD_STROKE_LINE_WIDTH } from '../constants';
import { Point, Rect, Size, StitchTypeEnum } from '../types/general';
import { GridData, PaletteBeadColor } from '../types/palette';
import { getBeadSize, getPaletteBeadColors } from '../utils';

const MAX_BEADS_PER_ROW_LEGEND = 7;
const BEAD_LEGEND_SIZE: Size = { width: 40, height: 48 };
const BEAD_LEGEND_RECT_SIZE: Size = { width: 193, height: 80 };

export class PatternPainter {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  stitchTypeId: StitchTypeEnum;
  rows: number;
  cols: number;
  grid: GridData;
  zoom: number;
  showGrid: boolean;

  constructor(
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D,
    stitchTypeId: StitchTypeEnum,
    cols: number,
    rows: number,
    grid: GridData,
    zoom: number,
    showGrid: boolean
  ) {
    this.canvas = canvas;
    this.ctx = ctx;
    this.stitchTypeId = stitchTypeId;
    this.cols = cols;
    this.rows = rows;
    this.grid = grid;
    this.zoom = zoom;
    this.showGrid = showGrid;
    this.drawPattern(false);
  }

  getPatternImage(): string {
    this.drawPattern(true);
    const image = this.canvas?.toDataURL('image/png', 1.0);
    return image;
  }

  getBeadLegendRect(i: number, beadSize: Size): Rect {
    return {
      x: (i % MAX_BEADS_PER_ROW_LEGEND) * BEAD_LEGEND_RECT_SIZE.width,
      y:
        Math.floor(i / MAX_BEADS_PER_ROW_LEGEND) * BEAD_LEGEND_RECT_SIZE.height,
      width: beadSize.width,
      height: beadSize.height,
    };
  }

  drawBeadLegend() {
    this.ctx.fillStyle = 'white';
    this.ctx.fillRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);

    const paletteBeadColors = getPaletteBeadColors(
      this.grid,
      this.cols,
      this.rows
    );

    for (let i = 0; i < paletteBeadColors.length; i++) {
      const paletteBeadColor = paletteBeadColors[i];

      const beadRect = this.getBeadLegendRect(i, BEAD_LEGEND_SIZE);

      const strokeLineWidth = BEAD_STROKE_LINE_WIDTH;
      const beadColor = paletteBeadColor.color;

      this.drawBead(beadRect, beadColor, strokeLineWidth);

      this.ctx.fillStyle = invert(beadColor, true);
      this.ctx.textBaseline = 'middle';
      this.ctx.textAlign = 'center';
      this.ctx.font = `24px Helvetica`;
      this.ctx.fillText(
        paletteBeadColor.label,
        beadRect.x + beadRect.width / 2,
        beadRect.y + beadRect.height / 2 + 1
      );

      this.ctx.fillStyle = 'black';
      this.ctx.textBaseline = 'top';
      this.ctx.textAlign = 'left';
      this.ctx.font = `Bold 12px Helvetica`;
      this.ctx.fillText(
        paletteBeadColor.name,
        beadRect.x + beadRect.width + 5,
        beadRect.y + beadRect.height / 2
      );

      this.ctx.fillStyle = 'black';
      this.ctx.textBaseline = 'bottom';
      this.ctx.textAlign = 'left';
      this.ctx.font = `10px Helvetica`;
      this.ctx.fillText(
        `(${paletteBeadColor.count})`,
        beadRect.x + beadRect.width + 5,
        beadRect.y + beadRect.height - beadRect.height / 3
      );
    }
  }

  getBeadLegendSize(): Size {
    const paletteBeadColors = getPaletteBeadColors(
      this.grid,
      this.cols,
      this.rows
    );

    let rows = Math.floor(paletteBeadColors.length / MAX_BEADS_PER_ROW_LEGEND);

    if (paletteBeadColors.length % MAX_BEADS_PER_ROW_LEGEND !== 0) {
      rows++;
    }

    return {
      width: BEAD_LEGEND_RECT_SIZE.width * MAX_BEADS_PER_ROW_LEGEND,
      height:
        BEAD_LEGEND_RECT_SIZE.height * rows +
        BEAD_LEGEND_RECT_SIZE.height * 0.5 * (rows - 1),
    };
  }

  resetCanvas(width: number, height: number) {
    this.canvas.width = width;
    this.canvas.height = height;
    this.ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D;
  }

  getLegendaImage(): {
    beadLegendImageData: string | undefined;
    beadLegendSize: Size;
  } {
    const beadLegendSize = this.getBeadLegendSize();
    this.resetCanvas(beadLegendSize.width, beadLegendSize.height);
    this.drawBeadLegend();
    const image = this.canvas?.toDataURL('image/png', 1.0);
    return { beadLegendImageData: image, beadLegendSize };
  }

  getBeadOffset(row: number, col: number): Point {
    const beadSize = getBeadSize(this.stitchTypeId, this.zoom);

    switch (this.stitchTypeId) {
      case StitchTypeEnum.Peyote:
        return { x: 0, y: col % 2 === 0 ? 0 : beadSize.height / 2 };
      case StitchTypeEnum.Brick:
        return { x: row % 2 === 0 ? beadSize.width / 2 : 0, y: 0 };
      case StitchTypeEnum.Loom:
        return { x: 0, y: 0 };
    }
  }

  getGridPosAtCanvasPos(x: number, y: number): Point | null {
    const beadSize = getBeadSize(this.stitchTypeId, this.zoom);

    let col: number;
    let row: number;

    switch (this.stitchTypeId) {
      case StitchTypeEnum.Peyote:
        col = Math.floor(x / beadSize.width);

        if (col % 2 === 0) {
          row = Math.floor(y / beadSize.height);
          return { x: col, y: row };
        } else {
          row = Math.floor((y - beadSize.height / 2) / beadSize.height);
          return { x: col, y: row };
        }

      case StitchTypeEnum.Brick:
        row = Math.floor(y / beadSize.height);

        if (row % 2 === 0) {
          col = Math.floor((x - beadSize.width / 2) / beadSize.width);
          return { x: col, y: row };
        } else {
          col = Math.floor(x / beadSize.width);
          return { x: col, y: row };
        }

      case StitchTypeEnum.Loom:
        col = Math.floor(x / beadSize.width);
        row = Math.floor(y / beadSize.height);
        return { x: col, y: row };
    }
  }

  drawBead(
    beadRect: Rect,
    beadColor: string,
    strokeLineWidth: number,
    label?: string
  ) {
    if (beadColor) {
      this.ctx.fillStyle = beadColor;

      this.ctx.fillRect(
        beadRect.x,
        beadRect.y,
        beadRect.width,
        beadRect.height
      );

      this.ctx.fillRect(
        beadRect.x + strokeLineWidth,
        beadRect.y + strokeLineWidth,
        beadRect.width - strokeLineWidth * 2,
        beadRect.height - strokeLineWidth * 2
      );
    } else {
      if (this.showGrid) {
        this.ctx.strokeStyle = BEAD_STROKE_COLOR;
        this.ctx.lineWidth = strokeLineWidth;
        this.ctx.strokeRect(
          beadRect.x,
          beadRect.y,
          beadRect.width,
          beadRect.height
        );
      }
    }

    if (label) {
      const fontSize = 5 * this.zoom;

      this.ctx.fillStyle = invert(beadColor, true);
      this.ctx.textBaseline = 'middle';
      this.ctx.textAlign = 'center';
      this.ctx.font = `${fontSize}px Helvetica`;
      this.ctx.fillText(
        label,
        beadRect.x + beadRect.width / 2,
        beadRect.y + beadRect.height / 2 + 1
      );
    }
  }

  drawPattern(isPrint: boolean) {
    const beadSize = getBeadSize(this.stitchTypeId, this.zoom);
    const strokeLineWidth = BEAD_STROKE_LINE_WIDTH * this.zoom;
    this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);

    let paletteBeadColors: PaletteBeadColor[] | undefined = undefined;

    if (isPrint) {
      paletteBeadColors = getPaletteBeadColors(this.grid, this.cols, this.rows);
    }

    for (let row = 0; row < this.rows; row++) {
      for (let col = 0; col < this.cols; col++) {
        const x = col * beadSize.width;
        const y = row * beadSize.height;
        const offset = this.getBeadOffset(row, col);

        const beadColor = this.grid?.[row]?.[col]?.color || '';

        let label: string | undefined = undefined;

        if (isPrint && beadColor !== '' && paletteBeadColors) {
          label = paletteBeadColors.find((pbc) => pbc.color === beadColor)
            ?.label;
        }

        const beadRect: Rect = {
          x: x + offset.x,
          y: y + offset.y,
          width: beadSize.width,
          height: beadSize.height,
        };

        this.drawBead(beadRect, beadColor, strokeLineWidth, label);
      }
    }
  }
}
