import { DropdownItem } from '@/app/components/general/Dropdown';
import { Category, TransferSettings } from '@prisma/client';
import {
  FloatingToolbarType,
  HistoryDataItem,
  ImageTransferPreEffectEnum,
  ImageTransferMethodEnum,
  SelectedContextEnum,
  Size,
  StitchTypeEnum,
  UnitOfMeasureEnum,
  WizardStep,
  ImageTransferDitherEnum,
  ToolbarMenuItemEnum,
} from './types/general';

export const unitOfMeasuresOptions: DropdownItem[] = [
  { value: UnitOfMeasureEnum.cm, label: 'cm' },
  { value: UnitOfMeasureEnum.mm, label: 'mm' },
  { value: UnitOfMeasureEnum.inch, label: 'in' },
];

export const imageTransferPreEffectOptions: DropdownItem[] = [
  { value: ImageTransferPreEffectEnum.None, label: 'No Change' },
  {
    value: ImageTransferPreEffectEnum.BlackAndWhite,
    label: 'Black & White',
  },
  { value: ImageTransferPreEffectEnum.GrayScale, label: 'Shades of Gray' },
  { value: ImageTransferPreEffectEnum.Sepia, label: 'Warm Vintage' },
];

export const imageTransferMethodOptions: DropdownItem[] = [
  {
    value: ImageTransferMethodEnum.MedianCut,
    label: 'Balanced Color Simplify',
  },
  {
    value: ImageTransferMethodEnum.DontReduceColorsWithLib,
    label: `Keep Original Colors`,
  },
  {
    value: ImageTransferMethodEnum.RemoveNearest,
    label: 'Basic Color Match',
  },
  {
    value: ImageTransferMethodEnum.WuQuantizer,
    label: 'Smooth Color Blend',
  },
  {
    value: ImageTransferMethodEnum.OctreeQuantizer,
    label: 'Precise Color Fit',
  },
];

export const imageTransferDitherOptions: DropdownItem[] = [
  { value: ImageTransferDitherEnum.None, label: 'No Texture' },
  {
    value: ImageTransferDitherEnum.Atkinson,
    label: 'Light Texture',
  },
  { value: ImageTransferDitherEnum.Burkes, label: 'Mild Texture' },
  {
    value: ImageTransferDitherEnum.FloydSteinberg,
    label: 'Balanced Texture',
  },
  {
    value: ImageTransferDitherEnum.JarvisJudiceNinke,
    label: 'Detailed Texture',
  },
  { value: ImageTransferDitherEnum.Sierra2, label: 'Fine Texture' },
  { value: ImageTransferDitherEnum.Sierra3, label: 'Rich Texture' },
  {
    value: ImageTransferDitherEnum.SierraLite,
    label: 'Subtle Texture',
  },
  {
    value: ImageTransferDitherEnum.StevensonArce,
    label: 'Vintage Texture',
  },
  { value: ImageTransferDitherEnum.Stucki, label: 'Soft Texture' },
];

export const MIN_ZOOM = 0.1;
export const MAX_ZOOM = 3;

const BEAD_SIZE_FACTOR = 8;
export const INITIAL_BEAD_SIZE: Size = {
  width: 72 / BEAD_SIZE_FACTOR,
  height: 56 / BEAD_SIZE_FACTOR,
};
export const BEAD_STROKE_LINE_WIDTH = 1;
export const BEAD_STROKE_COLOR = '#44A5FF';

export const DEFAULT_TRANSFER_SETTINGS: TransferSettings = {
  accountId: 0,
  paletteId: 1,
  customPaletteId: 0,
  maxColors: 10,
  preEffect: ImageTransferPreEffectEnum.None,
  transferMethod: ImageTransferMethodEnum.WuQuantizer,
  transferDither: ImageTransferPreEffectEnum.None,
  createdAt: new Date(),
  updatedAt: null,
  sqliteUserId: 0,
};

export const DEFAULT_CANVAS_DEBOUNCE_TIME = 300;

export const DEFAULT_FILTER_DEBOUNCE_TIME = 700;

export const MAX_IMAGE_SIZE: Size = { width: 1000, height: 1000 };

export const MAX_ROWS_API_RESPONSE = 99999;

export const MAX_ROWS_COLS_PATTERN = 1000;

export const COLOR_WHITE = 'white';
export const COLOR_LINK = '#3069FE';
export const COLOR_SECONDARY = '#808080';
export const COLOR_DARK2 = '#F6F6F6';

export const WIZARD_STEPS: WizardStep[] = [
  {
    title: 'Welcome to Bead Pattern Maker',
    message: 'App walkthrough',
    getPosition: async () => ({ top: 200 }),
    triangleClassName: undefined,
  },
  {
    title: 'Menu',
    message: 'Here you can open, save, print and transform your pattern.',
    getPosition: async (isMobile) => {
      const mainToolbar = document.getElementById(
        isMobile ? 'main-toolbar-mobile' : 'main-toolbar'
      );
      if (!mainToolbar) {
        return {};
      }
      const { top, height } = mainToolbar.getBoundingClientRect();
      return {
        top: top + height + (isMobile ? 0 : 16),
        left: isMobile ? 0 : 24,
      };
    },
    triangleClassName: 'triangle-top-left',
  },
  {
    title: 'Toolbar',
    message: 'Select to add, remove or fill beads for your pattern.',
    getPosition: async () => {
      const floatingToolbar = document.getElementById('floating-toolbar');
      if (!floatingToolbar) {
        return {};
      }
      const { top, height } = floatingToolbar.getBoundingClientRect();
      return {
        top: top + height + 16,
        left: 24,
      };
    },
    triangleClassName: 'triangle-top-left',
  },
  {
    title: 'Profile',
    message: 'Access to your profile.',
    getPosition: async () => {
      const profileButton = document.getElementById('profile-button');
      if (!profileButton) {
        return {};
      }
      const { top, height } = profileButton.getBoundingClientRect();
      return {
        top: top + height + 16,
        right: 16,
      };
    },
    triangleClassName: 'triangle-top-right',
  },
  {
    title: 'Palette',
    message: 'Pick up a color from the available palettes.',
    getPosition: async () => {
      const paletteSidebar = document.getElementById('palettes-sidebar');
      if (!paletteSidebar) {
        return {};
      }
      const { top, width } = paletteSidebar.getBoundingClientRect();
      return {
        top: top,
        right: width + 24,
      };
    },
    triangleClassName: 'triangle-right-top',
  },
  {
    title: 'Pattern',
    message: 'Information about your current pattern.',
    getPosition: async () => {
      const patternTopSettings = document.getElementById(
        'pattern-top-settings'
      );
      if (!patternTopSettings) {
        return {};
      }
      const { left, top, width, height } =
        patternTopSettings.getBoundingClientRect();
      return {
        top: top + height + 16,
        left: Math.max(left + width / 2 - 400 / 2, 0),
      };
    },
    triangleClassName: 'triangle-top-center',
  },
  {
    title: 'Canvas',
    message: "Here's where you draw your pattern.",
    getPosition: async () => {
      const canvas = document.getElementById('canvas');
      if (!canvas) {
        return {};
      }
      const { top, left, width, height } = canvas.getBoundingClientRect();
      const { innerHeight } = window;

      return {
        top: Math.min(top + height + 16, innerHeight - 200),
        left: Math.max(left + width / 2 - 400 / 2, 0),
      };
    },
    triangleClassName: 'triangle-top-center',
  },
  {
    title: 'Pattern Transform',
    message:
      "When you select the pattern, here's where you can apply transformation to it.",
    getPosition: async () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          const floatingPatternTransform = document.getElementById(
            'floating-pattern-transform'
          );
          if (!floatingPatternTransform) {
            return {};
          }
          const { left, width } =
            floatingPatternTransform.getBoundingClientRect();
          const { innerHeight } = window;

          resolve({
            top: innerHeight - 290,
            left: Math.max(left + width / 2 - 400 / 2, 0),
          });
        }, 100);
      });
    },
    triangleClassName: 'triangle-bottom-center',
    selectedContext: SelectedContextEnum.Pattern,
  },
  {
    title: 'Image Transform',
    message:
      "When you select the image, here's where you can apply transformation to it.",
    getPosition: async () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          const floatingImageTransform = document.getElementById(
            'floating-image-transform'
          );
          if (!floatingImageTransform) {
            return {};
          }
          const { left, width } =
            floatingImageTransform.getBoundingClientRect();
          const { innerHeight } = window;

          resolve({
            top: innerHeight - 290,
            left: Math.max(left + width / 2 - 400 / 2, 0),
          });
        }, 100);
      });
    },
    triangleClassName: 'triangle-bottom-center',
    selectedContext: SelectedContextEnum.Image,
  },
  {
    title: 'Enjoy',
    message: 'Happy beading!',
    getPosition: async () => {
      const canvas = document.getElementById('canvas');
      if (!canvas) {
        return {};
      }
      const { top, left, width, height } = canvas.getBoundingClientRect();
      const { innerHeight } = window;

      return {
        top: Math.min(top + height + 16, innerHeight - 200),
        left: Math.max(left + width / 2 - 400 / 2, 0),
      };
    },
    triangleClassName: 'triangle-top-center',
  },
];

export const IMAGE_TRANSFER_SUGGESTION_STEPS: WizardStep[] = [
  {
    title: 'Convert to Pattern',
    message: `Adjust your pattern over the image, then click 'Convert to Pattern' to finish.`,
    getPosition: async (isMobile) => {
      const mainToolbar = document.getElementById(
        isMobile
          ? 'main-toolbar-mobile'
          : `toolbar-menu-${ToolbarMenuItemEnum.TransferImage}`
      );
      if (!mainToolbar) {
        return {};
      }
      const { top, left, height } = mainToolbar.getBoundingClientRect();
      return {
        top: top + height,
        left: isMobile ? 0 : left - 24,
      };
    },
    triangleClassName: 'triangle-top-left',
  },
];

export const EMPTY_CATEGORY: Category = {
  id: 0,
  accountId: 0,
  name: '',
  description: '',
  sqliteCategoryId: 0,
  createdAt: new Date(),
  updatedAt: null,
  lastAccessAt: null,
};

export const INITIAL_STATE: HistoryDataItem = {
  stitchType: StitchTypeEnum.Brick,
  patternSize: { cols: 30, rows: 50 },
  patternPosition: { x: 130, y: 100 },
  patternZoom: 1,
  unitOfMeasureId: UnitOfMeasureEnum.inch,
  grid: [],
  referenceImage: undefined,
};

export const DEFAULT_TOOL = FloatingToolbarType.Drag;

export const DEFAULT_UOM: DropdownItem = unitOfMeasuresOptions.find(
  (uom) => uom.value === INITIAL_STATE.unitOfMeasureId
)!;

export const DEFAULT_PRE_EFFECT: DropdownItem = {
  value: ImageTransferPreEffectEnum.NotSelected,
  label: 'Select pre-effect',
};

export const MAX_N_CELLS_PER_LINE_WORD_CHART = 14;

export const PATTERN_TOP_PANEL_HEIGHT = 35;

export const PREVIEW_CANVAS_SIZE = { width: 123, height: 104 };

export const MENU_ITEM_TO_MESSAGE: Record<ToolbarMenuItemEnum, string> = {
  [ToolbarMenuItemEnum.New]: 'New',
  [ToolbarMenuItemEnum.Open]: 'Open',
  [ToolbarMenuItemEnum.Categories]: 'Categories',
  [ToolbarMenuItemEnum.Save]: 'Save',
  [ToolbarMenuItemEnum.SaveAs]: 'Save As',
  [ToolbarMenuItemEnum.Print]: 'Print',
  [ToolbarMenuItemEnum.DownloadPDF]: 'Download PDF',
  [ToolbarMenuItemEnum.UploadImage]: 'Upload Image',
  [ToolbarMenuItemEnum.ShowImage]: 'Show Image',
  [ToolbarMenuItemEnum.HideImage]: 'Hide Image',
  [ToolbarMenuItemEnum.TransferImage]: 'Convert to Pattern',
  [ToolbarMenuItemEnum.ClearPattern]: 'Clear Pattern',
  [ToolbarMenuItemEnum.Palettes]: 'Palettes',
  [ToolbarMenuItemEnum.Guide]: 'Guide',
  [ToolbarMenuItemEnum.Help]: 'Help',
};

export const CUSTOM_COLORS_PALETTE_ID = 100000;
export const CUSTOM_COLORS_PALETTE_NAME = 'Custom Colors';
